<template>
  <div>
    <b-sidebar
      id="sidebar-create"
      shadow
      right
      bg-variant="white"
      backdrop
      ref="sidebar-create"
      :title="dataProp.id == null ? 'Cadastrar Profissional' : 'Editar Profissional'"
    >
      <div>
        <validation-observer ref="simpleRules">
        <hr>
          <b-overlay :show="isLoadingStore" opacity="0.70" rounded="sm">
         <b-form enctype="multipart/form-data" method="POST">
          <b-form-group
            label="Nome:"
            label-for="i-nome"
            class="pl-1 pr-1"
          >
            <validation-provider
              #default="{ errors }"
              name="nome"
              rules="required"
            >
              <b-form-input
                id="i-nome"
                v-model="dataProfessionals.name"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Nome Social:"
            label-for="i-nome-social"
            class="pl-1 pr-1"
          >
            <validation-provider
              #default="{ errors }"
              name="social_name"
              rules=""
            >
              <b-form-input
                id="i-nome"
                v-model="dataProfessionals.social_name"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Telefone:"
            label-for="i-phone"
            class="pl-1 pr-1"
          >
            <validation-provider
              #default="{ errors }"
              name="telefone"
              rules="required"
            >
              <b-form-input
                id="i-phone"
                v-model="dataProfessionals.phone"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Profissão:"
            label-for="i-profession"
            class="pl-1 pr-1"
          >
            <validation-provider
              #default="{ errors }"
              name="titulo"
              rules="required"
            >
              <b-form-input
                id="i-profession"
                placeholder="Pedreiro"
                v-model="dataProfessionals.profession"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Número do registro ou CNPJ:"
            label-for="i-cnpj"
            class="pl-1 pr-1"
          >
            <validation-provider
              #default="{ errors }"
              name="número de registro ou CNPJ"
              rules="required"
            >
              <b-form-input
                id="i-zip"
                v-model="dataProfessionals.class_entity_registry"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group 
            label="Categoria:" 
            label-for="i-evento"
            class="pl-1 pr-1"
          >
            <validation-provider
              #default="{ errors }"
              name="categoria"
              rules="required"
            >
              <!-- <b-form-select
                id="i-evento"
                v-model="category_type_id"
                :options="categories"
                value-field="id"
                text-field="name"
                class="form-control"
                :state="errors.length > 0 ? false:null"
              /> -->
               <v-select
                  v-model="category_type_ids"
                  size="lg"
                  multiple
                  label="name"
                  value-field="id"
                  :options="categories"
                  :state="errors.length > 0 ? false:null"
                />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group 
            label="Subcategoria:" 
            label-for="i-evento"
            class="pl-1 pr-1"
          >
            <validation-provider
              #default="{ errors }"
              name="subcategoria"
              rules="required"
            >
              <!-- <b-form-select
                id="i-evento"
                v-model="dataProfessionals.event_type_ids"
                :options="categoryDetails"
                value-field="id"
                text-field="name"
                class="form-control"
                :disabled="showInput == false"
                :state="errors.length > 0 ? false:null"
              /> -->
                <v-select
                  v-model="dataProfessionals.event_type_ids"
                  size="lg"
                  multiple
                  label="name"
                  value-field="id"
                  :options="listSubcategory"
                  :state="errors.length > 0 ? false:null"
                  :disabled="showInput == false"
                />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="CEP:"
            label-for="i-zip"
            class="pl-1 pr-1"
          >
            <validation-provider
              #default="{ errors }"
              name="cep"
              rules="required"
            >
              <b-form-input
                id="i-zip"
                v-model="dataProfessionals.address.zip_address"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Rua:"
            label-for="i-street"
            class="pl-1 pr-1"
          >
            <validation-provider
              #default="{ errors }"
              name="rua"
              rules="required"
            >
              <b-form-input
                id="i-street"
                v-model="dataProfessionals.address.street"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Numero:"
            label-for="i-number"
            class="pl-1 pr-1"
          >
            <validation-provider
              #default="{ errors }"
              name="numero"
              rules="required"
            >
              <b-form-input
                id="i-number"
                v-model="dataProfessionals.address.number"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Complemento:"
            label-for="i-complement"
            class="pl-1 pr-1"
          >
            <b-form-input
              id="i-complement"
              v-model="dataProfessionals.address.complement"
            />
          </b-form-group>
          <b-form-group
            label="Bairro:"
            label-for="i-neighborhood"
            class="pl-1 pr-1"
          >
            <validation-provider
              #default="{ errors }"
              name="bairro"
              rules="required"
            >
              <b-form-input
                id="i-neighborhood"
                v-model="dataProfessionals.address.neighborhood"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Cidade:"
            label-for="i-city"
            class="pl-1 pr-1"
          >
            <validation-provider
              #default="{ errors }"
              name="cidade"
              rules="required"
            >
              <b-form-input
                id="i-city"
                v-model="dataProfessionals.address.city"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Estado:"
            label-for="i-state"
            class="pl-1 pr-1"
          >
            <validation-provider
              #default="{ errors }"
              name="estado"
              rules="required"
            >
              <b-form-input
                id="i-state"
                v-model="dataProfessionals.address.state"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Raio de atuação (Km):"
            label-for="i-operating_ranges"
            class="pl-1 pr-1"
          >
            <validation-provider
              #default="{ errors }"
              name="operating_range"
              rules="required"
            >
              <b-form-input
                id="i-state"
                v-model="dataProfessionals.operating_range"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-form>
        <b-row style="margin-right: 0rem !important;">
          <b-col lg="5" sm="6" class="ml-1 mr-1">
            <b-button
              variant="relief-primary"
              @click="storeProfessionals()"
              :disabled="dataProfessionals.name == null"
              block
            >
              {{this.dataProfessionals.id == null ? 'Salvar' : 'Editar'}}
            </b-button>
          </b-col>
          <b-col>
            <b-button 
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-danger"
              @click="closeSidebar"
              block
            >
              Fechar
            </b-button>
          </b-col>
        </b-row>
        </b-overlay>
        </validation-observer>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
/* eslint-disable */
import { BButton, BSidebar, VBToggle} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapActions, mapState } from "vuex";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  required, email, confirmed, password,
} from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'

export default {
  components: {
    BButton,
    BSidebar,
    ValidationProvider,
    ValidationObserver,
    vSelect
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
   props: { 
    dataProp: {
      type: Object,
      default: () => {}
    },
  },
  data() {
    return {
      showInput: false,
      category_type_ids: [],
      listSubcategory: [],
      dataProfessionals: {
        id: null,
        name: null,
        social_name: null,
        phone: null,
        profession: null,
        event_type_ids: [],
        operating_range: 30,
        class_entity_registry: null,
        address: {
          zip_address: null,
          street: null,
          number: null,
          complement: null, 
          neighborhood: null,
          city: null,
          state: null
        },
      },

      isLoadingStore: false,
      csrf: document.head.querySelector('meta[name="csrf-token"]') ? document.head.querySelector('meta[name="csrf-token"]').content : '',
    }
  },
  computed: {
    ...mapState("professionals", ["professionals"]),
    ...mapState("categories", ["categories", "categoryDetails"])
  },
  methods: {
    ...mapActions("professionals", ["store", "update"]),
    storeProfessionals(value) {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.isLoadingStore = true
          if (this.dataProp.id == null) { 
            this.store({
              ...this.dataProfessionals
            })
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Cadastrado com sucesso!',
                  icon: 'ThumbsUpIcon',
                  text: 'Operação executada com sucesso.',
                  variant: 'success'
                }
              })
              this.initValues();
              this.closeSidebar();
              this.$emit("fetch-details", 
              {
                active: true,
              });
            })
            .finally(() => {
              this.isLoadingStore = false
            })
          }else{
            this.update({
              ...this.dataProfessionals
            })
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Cadastrado com sucesso!',
                  icon: 'ThumbsUpIcon',
                  text: 'Operação executada com sucesso.',
                  variant: 'success'
                }
              })
              this.initValues();
              this.closeSidebar();
              this.$emit("fetch-details", 
              {
                active: true,
              });
            })
            .finally(() => {
              this.isLoadingStore = false
            })
          }
        }else{
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Erro de validação!',
              icon: 'AlertTriangleIcon',
              text: 'Por favor preencha todos os campos obrigatorios.',
              variant: 'danger'
            }
          })
        }
      })
    },
    initValues(){
      this.listSubcategory = []
      this.category_type_ids = [],
      this.dataProfessionals.id = null,
      this.dataProfessionals.name = null,
      this.dataProfessionals.social_name = null,
      this.dataProfessionals.phone = null,
      this.dataProfessionals.profession = null,
      this.dataProfessionals.event_type_ids = [],
      this.dataProfessionals.operating_range = 30,
      this.dataProfessionals.class_entity_registry = null,
      this.dataProfessionals.address.zip_address = null,
      this.dataProfessionals.address.street = null,
      this.dataProfessionals.address.number = null,
      this.dataProfessionals.address.complement = null, 
      this.dataProfessionals.address.neighborhood = null,
      this.dataProfessionals.address.city = null,
      this.dataProfessionals.address.state = null

      this.$nextTick(() => this.$refs.simpleRules.reset());
    },
    closeSidebar(){
      this.$refs['sidebar-create'].hide()
    },
    fetchCategories(paginated = false) {
      this.isLoading = true;
      this.$store
        .dispatch("categories/fetchCategories", {
          pagination: paginated
        })
        .then((response) => {
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    fetchSubCategories(vals) {
      this.isLoading = true;
      this.showInput = false;
      this.listSubcategory = [];
      let data = vals
      data.forEach((value, index) => {
        this.$store
          .dispatch("categories/fetchDetails", {
            category_id: value.id,
          })
          .then((response) => {
             response.forEach((value, index) => {
               this.listSubcategory.push(value);
             })
            this.showInput = true;
          })
          .catch((err) => {
            console.error(err);
          })
          .finally(() => {
            this.isLoading = false;
          });
        })
        
    },
    
  },
  
  created() {
    this.fetchCategories();
  },
  watch: {
    dataProp: {
      immediate: true, 
      handler (val, oldVal) {
        if (!val) return; 
        if (this.dataProp.id == null)  { 
          this.category_type_ids = []
          this.initValues(); 
          
        } else { 
          this.category_type_ids = this.dataProp.event_type.event_type_id,
          this.dataProfessionals.id = this.dataProp.id,
          this.dataProfessionals.name = this.dataProp.name,
          this.dataProfessionals.social_name = this.dataProp.social_name,
          this.dataProfessionals.phone = this.dataProp.phone,
          this.dataProfessionals.profession = this.dataProp.profession,
          this.dataProfessionals.event_type_id = this.dataProp.event_type.id,
          this.dataProfessionals.operating_range = this.dataProp.operating_range,
          this.dataProfessionals.class_entity_registry = this.dataProp.class_entity_registry,
          this.dataProfessionals.address.zip_address = this.dataProp.address.zip_address,
          this.dataProfessionals.address.street = this.dataProp.address.street,
          this.dataProfessionals.address.number = this.dataProp.address.number,
          this.dataProfessionals.address.complement = this.dataProp.address.complement, 
          this.dataProfessionals.address.neighborhood = this.dataProp.address.neighborhood,
          this.dataProfessionals.address.city = this.dataProp.address.city,
          this.dataProfessionals.address.state = this.dataProp.address.state
        } 
      }
    },
    category_type_ids(vals){
      this.fetchSubCategories(vals)
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
